<template>
  <div>
    <my-header></my-header>
    <div class="companybox" :style="{}">
      <div class="company-header">
        <div class="company-header-top">
          <span
            :style="{
              fontSize: '14px',
              color: '#666',
              padding: '6px',
            }"
          >
            全部结果:
          </span>
          <!-- 我的申请记录 -->
          <span class="company-lable" :style="{ marginLeft: '900px' }">
            <el-link type="primary" @click="$router.push('/applicationRecord')"
              >我的申请记录</el-link
            >
          </span>
        </div>
        <div
          class="company-header-box"
          :style="{ height: pagination ? '200px' : '' }"
        >
          <div class="company-header-box-title">商家：</div>
          <div class="company-header-box-value">
            <span v-for="(v, k) of companyList" :key="k">
              <span
                :style="{
                  color: companyItem[0].id == v.id ? '#e4393c' : ' #005aa0',
                }"
                @click="lookcompany(v.id)"
                >{{ v.companyName }}</span
              >
            </span>
            <div class="pagin-box">
              <el-pagination
                v-if="pagination"
                background
                layout="prev, pager, next"
                :total="paginationNum"
                :page-size="pageSize"
                @current-change="handleCurrentChange"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </div>
          <div class="company-header-box-buttom">
            <el-button
              :style="{ marginTop: '15px' }"
              v-text="pagination ? '收起' : '更多'"
              @click="pagination = !pagination"
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 页面初始显示 -->
      <div class="company-box">
        <div class="company-box-item" v-for="(v, k) of companyList2" :key="k">
          <div class="company-box-item-img">
            <img :src="v.companyLogo" alt="" />
          </div>
          <div class="company-box-item-name">
            <span>{{ v.companyName }}</span>
          </div>
          <div class="company-box-item-button">
            <!-- <span @click="tocertificate(v.id)">查看商家证书</span> -->
            <span @click="lookcompany(v.id)">申请加入商家</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import myHeader from "../components/my-header.vue";
export default {
  data() {
    return {
      // 总页数
      paginationNum: "",
      // 分页显示隐藏
      pagination: false,
      // 商家列表
      companyList: [],
      // 页面只显示5个详细信息
      companyList2: [],
      // 点击详情显示一个商家信息
      companyItem: [{ id: "" }],
      // 商家旗下所有商品
      companyListItem: [],
      // 页面信息数据
      companyListpages: [],
      //  url传送的商家名称
      companyName: "",
      // 商家名称分页
      pageNum: 1,
      pageSize: 10,
      //   // 商家商品分页
      //   itempageNum: 1,
      //   itempageSize: 8,
      //   // 总页数
      //   itempaginationNum: "",
      // 记录当前点击的商家id
      itemid: "",
      type: 3,
    };
  },
  // 权限信息
  computed: {
    roleId() {
      return sessionStorage.getItem("roleId");
    },
  },
  methods: {
    // 跳转证书详情页
    tocertificate(id) {
      sessionStorage.setItem("companyId", id);
      this.$router.push("certificate");
    },
    async getcompany() {
      const res = await this.$http({
        method: "get",
        url: `front/company/findList?pageNum=${this.pageNum}&pageSize=${this.pageSize}`,
      });
      console.log(res.data.data.list);
      console.log(res.data.data.pages * this.pageSize);
      this.paginationNum = res.data.data.pages * this.pageSize;
      res.data.data.list.map((v, k) => {
        if (v.type == 3 || v.type == 1) {
          this.companyList2.push(v);
          this.companyList.push(v);
        }
      });
      this.companyListpages = res.data.data;
    },
    //根据公司类型获取公司列表
    lookcompanytype(type) {
      this.getcompany(type);
      this.type = type;
    },
    // 改变当前页触发
    handleCurrentChange(num) {
      this.pageNum = num;
      this.getcompany();
    },
    itemhandleCurrentChange(num) {
      this.itempageNum = num;
      this.lookcompany(this.itemid);
    },
    // 查看店铺商品
    async lookcompany(id) {
      this.$router.push({
        path: "/CompanyJoinItem",
        query: {
          id: id,
        },
      });

      this.itemid = id;
    },
    // 去往详情页
    async toMessage(item) {
      const res = await this.$http({
        method: "get",
        url: "front/product/findById?id=" + item.id,
      });
      this.messageList = res.data.data;
      this.$router.push({
        path: "/goodMessage",
        query: { name: JSON.stringify(this.messageList) },
      });
    },
    async routeritem() {
      const query = this.$route.query;
      // console.log(JSON.parse(query.id));
      console.log(query.id);
      if (query.id === undefined) {
        console.log();
      } else {
        console.log(query);
        const id = query.id;
        this.lookcompany(id);
      }
    },
  },
  created() {
    this.getcompany();
    this.routeritem();
  },
  components: { myHeader },
};
</script>
<style scoped>
.companybox {
  width: 1150px;
  margin: 0 auto;
  margin-top: 160px;
}
.company-lable {
  font-size: 14px;
  font-weight: 600;
  color: #666;
}
.company-header-box {
  position: relative;
  display: flex;
  height: 70px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #aaa;
}
.company-header-top {
  margin: 10px 0;
}
.company-header-box .company-header-box-title {
  display: inline-block;
  background: #ddd;
  color: #555;
  padding: 6px;
  width: 120px;
}
.company-header-box .company-header-box-value {
  flex: 18;
  display: flex;
  flex-wrap: wrap;
}
.company-header-box .company-header-box-value span {
  box-sizing: border-box;
  width: 14%;
  padding: 6px;
  color: #005aa0;
  height: 50%;
  cursor: pointer;
}
.company-header-box .company-header-box-buttom {
  flex: 2;
}
.pagin-box {
  position: absolute;
  bottom: 20px;
  display: inline-block;
  right: 50px;
}
.pagin-box-item {
  margin: 30px 0px 60px 700px;
}
.company-box {
  margin-bottom: 50px;
}
.company-box-item {
  display: flex;
  background: url("../assets/images/bg-shop.png");
  margin: 20px 0;
}
.company-box-item-img {
  height: 100px;
  width: 180px;
  padding: 10px;
}
.company-box-item-img img {
  height: 100%;
  width: 100%;
}
.company-box-item-button span {
  background: #e4393c;
  color: #fff;
  margin-left: 45px;
  padding: 8px 25px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 120px;
  cursor: pointer;
}
.company-box-item-name {
  width: 600px;
}
.company-box-item-name span {
  font-size: 14px;
  color: #333;
  line-height: 60px;
}
.goodlist-list {
  display: flex;
  /* flex: 1; */
  flex-wrap: wrap;
  width: 100%;
  min-width: 800px;
  /* height: 1500px; */
  margin: 0 auto;
  margin-top: 20px;
}
.goodlist-list-box {
  width: 23%;
  margin: 0 10px;
  min-width: 200px;
  /* height: 340px; */
}
.goodlist-list-box-box {
  width: 85%;
  margin: 10px;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 6px;
}
.goodlist-list-box-box:hover {
  box-shadow: darkgrey 0px 4px 19px 3px;
  /* height: 350px; */
}
.goodlist-include-box {
  width: 100%;
  height: 160px;
  margin: 0 auto;
  margin-top: 5%;
}
.goodlist-list-img {
  width: 100%;
  height: 180px;
  border-radius: 5px;
  /* margin-left: 22%;
    margin-top: 12%; */
}
.goodlist-list-txt {
  width: 90%;
  margin: auto;
  font-size: 13px;
  margin-top: 35px;
  margin-left: 15px;
}
.goodlist-list-txt:hover p {
  color: rgb(123, 123, 224);
}
.price-row {
  margin-top: 12px;
  margin-left: 5px;
}
.price-row span {
  color: #e4393c;
  font-size: 20px;
}
.goodlist-bottom {
  margin: 0 auto;
  display: flex;
  width: 70%;
  height: 50px;
  border: 0.5px solid #aaa;
  margin-top: 15px;
  border-radius: 4px;
}
.goodlist-bottom:hover {
  border: 0.5px solid #1d1a1a;
}
.goodlist-bottom-left {
  width: 1000%;
  height: 100%;
  background: #df3033;
  color: #fff;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.goodlist-bottom-left span {
  color: #fff;
}
.goodlist-bottom-left span {
  line-height: 50px;
}
.box-card {
  width: 75%;
  margin: 0 auto;
  text-align: center;
  height: 200px;
  margin-top: 20px;
}
.box-card div {
  color: #666;
  line-height: 150px;
}
</style>